import { WOW } from 'wowjs';

(function($) {
  "use strict"; // Start of use strict

  // Load WOW.js on non-touch devices
  var isPhoneDevice = "ontouchstart" in document.documentElement
  $(document).ready(function() {
    if (isPhoneDevice) {
      //mobile
      $('body').addClass('wow-disabled')
    } else {
      //desktop
      // Initialize WOW.js
      const wow = new WOW({
        offset: 50
      })
      wow.init()
    }
    $('.loading-overlay').addClass('loading')
    setTimeout(function() {
      $('.loading-overlay').addClass('loaded').removeClass('loading')
    }, 250)
    $(window).on('beforeunload', function(event) {
      $('.loading-overlay').addClass('unloading')
    })
  });

  // Handler for scroll position
  var lastScrollTop = 0;
  function onScrollHandler() {
    var scrollTop = $(window).scrollTop()

    // Determine whether to collapse #top-bar
    var collapseTopBar = false
    // If we've scrolled down, collapse the top-bar
    if (lastScrollTop < scrollTop) {
      collapseTopBar = true
    } else {
      collapseTopBar = false
    }
    // Look for any .cover-navs
    // If we've scrolled past the bottom of the previous
    // element, we un-collapse the top-bar.
    // We also assume that .cover-nav has a dark background, so we add
    // .has-dark-background to body.
    var $p = $('.cover-nav').prev()
    if ($p.length > 0) {
      var offset = $p.offset().top + ($p.is('.fixed-top') ? 0 : $p.outerHeight())
      if (scrollTop > offset - 110) {
        $('body').addClass('has-dark-background')
        collapseTopBar = false
        $('.cover-nav .page-active').removeClass('inactive')
      } else {
        $('body').removeClass('has-dark-background')
        $('.cover-nav .page-active').addClass('inactive')
        $('.cover-nav .active').removeClass('active')
      }
    }
    collapseTopBar ? $("#top-bar").addClass("top-bar-collapse") : $("#top-bar").removeClass("top-bar-collapse")

    // Dismiss any .summary-overlay elements
    if (scrollTop > 2) {
      var $o = $('.summary-overlay:not(.dismissing),.summary-overlay:not(.dismissed)')
      if ($o.length > 0) {
        $o.addClass('dismissing')
        setTimeout(function() {
          $('.summary-overlay.dismissing').addClass('dismissed').removeClass('dismissing')
        }, 250)
      }
    } else {
      $('.summary-overlay').removeClass('dismissing').removeClass('dismissed')
    }
    lastScrollTop = scrollTop
  }

  // Dismiss any summary-overlays when you click on them
  $('body').on('click', '.summary-overlay *,.summary-overlay', function(event) {
    $(event.target).closest('.summary-overlay').addClass('dismissing')
    setTimeout(function() {
      $('.summary-overlay.dismissing').addClass('dismissed').removeClass('dismissing')
    }, 250)
  })

  $(window).scroll(onScrollHandler)
  $(document).ready(onScrollHandler)
  //$(document).scroll(slideySlide.trigger)

  // jQuery for page scrolling feature - requires jQuery Easing plugin
  $('a.page-scroll').bind('click', function(event) {
      var $anchor = $(this)
      $('html, body').stop().animate({
          scrollTop: $($anchor.attr('href')).offset().top
      }, 1500, 'easeInOutExpo')
      event.preventDefault()
  })

  // Closes the Responsive Menu on menu item click
  $('.navbar-collapse ul li a').click(function() {
    if ($(this).attr('class') != 'dropdown-toggle active' && $(this).attr('class') != 'dropdown-toggle') {
      $('.navbar-toggler:visible').click();
    }
  })

  var path = window.location.pathname
  var bits = path.split('/')
  if (bits.length > 3) {
    bits.splice(2, 1)
    path = bits.join('/')
  }
  $('.navigation .nav-item a[href="' + path + '"]').parent().addClass('page-active')

  // Change the background image of cover-nav when a nav link inside it gets
  // 'activated', either by hovering over it, or activated programmatically.
  $(document).on('mouseover', '.cover-nav .navigation a.nav-link', function(event) {
    var $e = $(event.target)
    $e.parent().addClass('active')
    if ($e.parent().not('.page-active')) {
      $e.closest('.cover-nav').find('.page-active').addClass('inactive')
    }
  })
  $(document).on('mouseout', '.cover-nav .navigation a.nav-link', function(event) {
    var $e = $(event.target)
    $(event.target).parent().removeClass('active')
    $e.closest('.cover-nav').find('.page-active').removeClass('inactive')
  })

  // On NavigationPage, cycle through the cover-nav items
  var $es = $('header.cover-nav .navigation a.nav-link')
  if ($es.length) {
    // First, highlight the first item
    $($es[0]).parent().addClass('page-active')
    // Periodically highlight the next item
    setInterval(function() {
      var $es = $('header.cover-nav .navigation a.nav-link')
      var $curr = $('header.cover-nav').find('.page-active')
      $curr.removeClass('page-active').removeClass('inactive')
      var index = $curr.index()
      index += 1
      index %= $es.length
      var $e = $($es.get(index)).parent()
      if ($('header.cover-nav .navigation .active').length > 0 &&
          $('header.cover-nav .navigation .active')[0] != $e[0]) {
        $e.addClass('inactive')
      }
      $e.addClass('page-active')
    }, 3500)
  }

  // $('.block-custom_html > pre, .block-editable > pre').each(function(index, element) {
  //   hljs.highlightBlock(element)
  // });

})(jQuery); // End of use strict
